<template>
	<div>
		<div class="pd30">
			<div >
			  <a-month-picker  valueFormat="YYYY-MM" v-model="search.month" format="YYYY-MM" @change="changeMonth"/>
			</div>
			
			<div class="mt30">
				<div class="wxb-table-gray">
					<a-table rowKey="commission_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
					 :loading="loading">
						<div class="flex alcenter center" slot="monetary" slot-scope="monetary,record">
							{{monetary}}元
						</div>
						
						<div class="flex alcenter center" slot="commission" slot-scope="commission,record">
							{{commission}}元
						</div>
					</a-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default{
		props:{
			partner_id:{
				type:Number,
				default:0
			},
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				search:{
					month:''
				},
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '月份',dataIndex: 'month',align: 'center',ellipsis: true},
					{title: '客户业绩金额',dataIndex: 'monetary',align: 'center',scopedSlots: {customRender: 'monetary'}},
					{title: '分红收益',dataIndex: 'commission',align: 'center',scopedSlots: {customRender: 'commission'}},
				],
				datas: [],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getPartnerIncomeList',{
					partner_id:this.partner_id,
					month:this.search.month,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					role_id:1,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			changeMonth(){
				this.pagination.current=1;
				this.getLists();
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
